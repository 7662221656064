import React, { useState } from "react";
import { FormikProps, Form, withFormik } from "formik";
import * as Yup from "yup";
import {
  ValidationMessages,
  ValidationSettings,
} from "../../Common/Validations";
import {
  EmailField,
  PasswordField,
  SubmitButton,
} from "../../Common/FormComponents";
import { FormFieldContainer } from "../../Common/FormFieldContainer";
import { Box, InputLabel } from "@material-ui/core";
import { uniqueId } from "lodash";

const ValidationSchema = Yup.object().shape({
  username: Yup.string()
    .email(ValidationMessages.emailFormatError)
    .required(ValidationMessages.emailRequiredError),
  newPassword: Yup.string()
    .min(
      ValidationSettings.passwordMinimumCharacters,
      ValidationMessages.passwordMinimumError
    )
    .required(ValidationMessages.passwordRequiredError),
  confirmNewPassword: Yup.string()
    .required(ValidationMessages.confirmPasswordRequiredError)
    .oneOf(
      [Yup.ref("newPassword")],
      ValidationMessages.passwordsMustMatchError
    ),
});

interface InnerFormProps {
  submitButtonText: string;
}

const InnerForm = (
  props: InnerFormProps & FormikProps<ForgotPasswordFormValues>
) => {
  const { isSubmitting, submitButtonText } = props;

  const [usernameFieldId] = useState(uniqueId("loginForm-"));
  const [newPasswordFieldId] = useState(uniqueId("loginForm-"));
  const [confirmNewPasswordFieldId] = useState(uniqueId("loginForm-"));

  return (
    <Form>
      <FormFieldContainer>
        <InputLabel htmlFor={usernameFieldId}>
          <Box
            component="div"
            textAlign="left"
            fontSize="0.8125rem"
            fontWeight="700"
            children={"Email"}
            color="secondary.main"
          />
        </InputLabel>
        <EmailField name="username" id={usernameFieldId} />
      </FormFieldContainer>
      <FormFieldContainer>
        <InputLabel htmlFor={newPasswordFieldId}>
          <Box
            component="div"
            textAlign="left"
            fontSize="0.8125rem"
            fontWeight="700"
            children={"New Password"}
            color="secondary.main"
          />
        </InputLabel>
        <PasswordField
          name="newPassword"
          autoComplete="new-password"
          id={newPasswordFieldId}
        />
      </FormFieldContainer>
      <FormFieldContainer>
        <InputLabel htmlFor={confirmNewPasswordFieldId}>
          <Box
            component="div"
            textAlign="left"
            fontSize="0.8125rem"
            fontWeight="700"
            children={"Confirm New Password"}
            color="secondary.main"
          />
        </InputLabel>
        <PasswordField
          name="confirmNewPassword"
          autoComplete="new-password"
          id={confirmNewPasswordFieldId}
        />
      </FormFieldContainer>
      <FormFieldContainer>
        <SubmitButton fullWidth={false} disabled={isSubmitting}>{submitButtonText}</SubmitButton>
      </FormFieldContainer>
    </Form>
  );
};

interface ForgotPasswordFormProps {
  submitButtonText: string;
  onSubmit(values: ForgotPasswordFormValues): Promise<void>;
}

export interface ForgotPasswordFormValues {
  username: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const ForgotPasswordForm = withFormik<
  ForgotPasswordFormProps,
  ForgotPasswordFormValues
>({
  mapPropsToValues: (props) => {
    return {
      username: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  },

  validationSchema: ValidationSchema,

  handleSubmit: async (values, { props }) => {
    await props.onSubmit(values);
  },
})(InnerForm);
