export interface LoginResponse {
  redirectUrl: string;
  requiresFurtherAction: boolean;
  furtherActionStatus:
    | "TwoFactorLoginRequired"
    | "AccountConfirmationRequired"
    | "PhoneConfirmationRequired"
    | "PasswordResetRequired";
}
export class AccountsClient {
  static async Login(
    username: string,
    password: string,
    returnUrl: string
  ): Promise<LoginResponse | null> {
    const loginResponse = await fetch(
      `${window.location.origin}/account/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({
          username: username,
          password: password,
          returnUrl: returnUrl,
        }),
      }
    );
    if(loginResponse.ok)
    {
      return (await loginResponse.json()) as LoginResponse;
    }
    else
    {
      return null;
    }

  }

  static async TwoFactorLogin(returnUrl: string, confirmationCode: string) : Promise<LoginResponse | null>
  {
    const twoFactorLoginRequest = await fetch(
      `${window.location.origin}/account/login/twofactor/verify`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({
          returnUrl: returnUrl,
          confirmationCode: confirmationCode,
        }),
      }
    );
    if(twoFactorLoginRequest.ok)
    {
      return (await twoFactorLoginRequest.json()) as LoginResponse;
    }
    else
    {
      return null;
    }
  }

  static async Register(
    name: string,
    username: string,
    password: string,
    phoneNumber: string
  ): Promise<boolean> {
    const registerResponse = await fetch(
      `${window.location.origin}/account/register/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          username: username,
          password: password,
          phoneNumber: phoneNumber,
        }),
      }
    );
    if (registerResponse.ok) {
      return true;
    } else {
      return false;
    }
  }

  static async RegisterConfirmEmail(
    confirmationCode: string,
    username: string | null
  ): Promise<boolean> {
    const registerConfirmEmailResponse = await fetch(
      `${window.location.origin}/account/register/confirm/email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({
          confirmationCode: confirmationCode,
          username: username
        }),
      }
    );
    if (registerConfirmEmailResponse.ok) {
      return true;
    } else {
      return false;
    }
  }
}
