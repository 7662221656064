import {
  Container,
  CardHeader,
  Typography,
  CardContent,
} from "@material-ui/core";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BlueLeapCard } from "../../Components/Common/BlueLeap";

export const ErrorPage: React.FC<RouteComponentProps> = (props) => {
  return (
    <Container maxWidth="sm">
      <BlueLeapCard>
        <CardHeader
          title={
            <Typography
              gutterBottom
              variant="h3"
              component="h2"
              children={"Error"}
              color="primary"
            />
          }
        />
        <CardContent>
          <Typography color="primary">
            Uh Oh, you are not supposed to see this!
          </Typography>
        </CardContent>
      </BlueLeapCard>
    </Container>
  );
};
