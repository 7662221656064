import React, { useState } from "react";
import { Form, FormikProps, withFormik } from "formik";
import {
  Box,
  InputLabel,
} from "@material-ui/core";
import { FormFieldContainer } from "../../Common/FormFieldContainer";
import {
  PasswordField,
  EmailField,
  SubmitButton,
} from "../../Common/FormComponents";
import * as Yup from "yup";
import {
  ValidationMessages,
  ValidationSettings,
} from "../../Common/Validations";
import { Link } from "react-router-dom";
import { uniqueId } from "lodash";

export const LoginFormValidationSchema = Yup.object().shape({
  username: Yup.string()
    .email(ValidationMessages.emailFormatError)
    .required(ValidationMessages.emailRequiredError),
  password: Yup.string()
    .min(
      ValidationSettings.passwordMinimumCharacters,
      ValidationMessages.passwordMinimumError
    )
    .required(ValidationMessages.passwordRequiredError),
});

interface InnerFormProps {
  submitButtonText: string;
}

const InnerForm = (props: InnerFormProps & FormikProps<LoginFormValues>) => {
  const { isSubmitting, submitButtonText } = props;

  const [usernameFieldId] = useState(uniqueId("loginForm-"));
  const [passwordFieldId] = useState(uniqueId("loginForm-"));
  return (
    <Form>
      <FormFieldContainer>
        <InputLabel htmlFor={usernameFieldId}>
          <Box
            component="div"
            textAlign="left"
            fontSize="0.8125rem"
            fontWeight="700"
            children={"Email"}
            color="secondary.main"
          />
        </InputLabel>
        <EmailField name="username" placeholder="Email" id={usernameFieldId} />
      </FormFieldContainer>
      <FormFieldContainer>
        <InputLabel htmlFor={passwordFieldId}>
          <Box
            component="div"
            textAlign="left"
            fontSize="0.8125rem"
            fontWeight="700"
            children={"Password"}
            color="secondary.main"
          />
        </InputLabel>
        <PasswordField
          name="password"
          id={passwordFieldId}
          autoComplete="current-password"
          placeholder="Password"
        />
      </FormFieldContainer>
      <Box
        justifyContent="space-between"
        display="flex"
        flexDirection="row"
        m={1}
      >
        <Box>
          <Link to="/forgotpassword" style={{ textDecorationStyle: "dashed" }}>
            Forgot Password
          </Link>
        </Box>
        <Box mt={2}>
          <SubmitButton fullWidth={false} disabled={isSubmitting}>
            {submitButtonText}
          </SubmitButton>
        </Box>
      </Box>
    </Form>
  );
};

export interface LoginFormValues {
  username: string;
  password: string;
}

export interface LoginFormProps {
  initialUsername?: string;
  submitButtonText: string;
  onSubmit(values: LoginFormValues): Promise<void>;
}

export const LoginForm = withFormik<LoginFormProps, LoginFormValues>({
  mapPropsToValues: (props) => {
    return {
      username: props.initialUsername ?? "",
      password: "",
    };
  },

  validationSchema: LoginFormValidationSchema,

  handleSubmit: async (values, { props }) => {
    await props.onSubmit(values);
  },
})(InnerForm);
