import React, { useState } from "react";
import { FormikProps, Form, withFormik } from "formik";
import * as Yup from "yup";
import {
  ValidationMessages,
  ValidationSettings,
} from "../../Common/Validations";
import {
  EmailField,
  PasswordField,
  PhoneField,
  StyledTextField,
  SubmitButton,
} from "../../Common/FormComponents";
import { FormFieldContainer } from "../../Common/FormFieldContainer";
import { Box, InputLabel } from "@material-ui/core";
import { uniqueId } from "lodash";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(ValidationMessages.nameRequiredError)
    .min(1, ValidationMessages.nameMinimumError),
  username: Yup.string()
    .email(ValidationMessages.emailFormatError)
    .required(ValidationMessages.emailRequiredError),
  phoneNumber: Yup.string().required(
    ValidationMessages.phoneNumberRequiredError
  ),
  password: Yup.string()
    .min(
      ValidationSettings.passwordMinimumCharacters,
      ValidationMessages.passwordMinimumError
    )
    .required(ValidationMessages.passwordRequiredError),
  confirmPassword: Yup.string()
    .required(ValidationMessages.confirmPasswordRequiredError)
    .oneOf([Yup.ref("password")], ValidationMessages.passwordsMustMatchError),
});

interface InnerFormProps {
  submitButtonText: string;
}

const InnerForm = (props: InnerFormProps & FormikProps<RegisterFormValues>) => {
  const { isSubmitting, submitButtonText } = props;

  const [nameFieldId] = useState(uniqueId("loginForm-"));
  const [usernameFieldId] = useState(uniqueId("loginForm-"));
  const [passwordFieldId] = useState(uniqueId("loginForm-"));
  const [confirmPasswordFieldId] = useState(uniqueId("loginForm-"));
  const [phoneNumberFieldId] = useState(uniqueId("loginForm-"));

  return (
    <Form>
      <FormFieldContainer>
        <InputLabel htmlFor={nameFieldId}>
          <Box
            component="div"
            textAlign="left"
            fontSize="0.8125rem"
            fontWeight="700"
            children={"Name"}
            color="secondary.main"
          />
        </InputLabel>
        <StyledTextField name="name" id={nameFieldId} />
      </FormFieldContainer>
      <FormFieldContainer>
        <InputLabel htmlFor={usernameFieldId}>
          <Box
            component="div"
            textAlign="left"
            fontSize="0.8125rem"
            fontWeight="700"
            children={"Email"}
            color="secondary.main"
          />
        </InputLabel>
        <EmailField name="username" id={usernameFieldId} />
      </FormFieldContainer>
      <FormFieldContainer>
        <InputLabel htmlFor={phoneNumberFieldId}>
          <Box
            component="div"
            textAlign="left"
            fontSize="0.8125rem"
            fontWeight="700"
            children={"Phone"}
            color="secondary.main"
          />
        </InputLabel>
        <PhoneField name="phoneNumber" id={phoneNumberFieldId} />
      </FormFieldContainer>
      <FormFieldContainer>
        <InputLabel htmlFor={passwordFieldId}>
          <Box
            component="div"
            textAlign="left"
            fontSize="0.8125rem"
            fontWeight="700"
            children={"Password"}
            color="secondary.main"
          />
        </InputLabel>
        <PasswordField
          name="password"
          autoComplete="new-password"
          id={passwordFieldId}
        />
      </FormFieldContainer>
      <FormFieldContainer>
        <InputLabel htmlFor={confirmPasswordFieldId}>
          <Box
            component="div"
            textAlign="left"
            fontSize="0.8125rem"
            fontWeight="700"
            children={"Confirm Password"}
            color="secondary.main"
          />
        </InputLabel>
        <PasswordField
          name="confirmPassword"
          autoComplete="new-password"
          id={confirmPasswordFieldId}
        />
      </FormFieldContainer>
      <FormFieldContainer>
        <SubmitButton fullWidth={false} disabled={isSubmitting}>
          {submitButtonText}
        </SubmitButton>
      </FormFieldContainer>
    </Form>
  );
};

interface RegisterFormProps {
  submitButtonText: string;
  onSubmit(values: RegisterFormValues): Promise<void>;
}

export interface RegisterFormValues {
  name: string,
  username: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
}

export const RegisterForm = withFormik<RegisterFormProps, RegisterFormValues>({
  mapPropsToValues: (props) => {
    return {
      name: "",
      username: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
    };
  },

  validationSchema: ValidationSchema,

  handleSubmit: async (values, { props }) => {
    await props.onSubmit(values);
  },
})(InnerForm);
