import React, { useEffect, useState } from "react";
import "./App.scss";
import { Box, Container, ThemeProvider } from "@material-ui/core";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { BlueLeapTheme } from "./Themes/BlueLeap/BlueLeap";
import { BlueLeapHeader } from "./Components/Common/BlueLeap";
import { RegisterPage } from "./Pages/RegisterPage/RegisterPage";
import { LoginPage } from "./Pages/LoginPage/LoginPage";
import { LogoutPage } from "./Pages/LogoutPage/LogoutPage";
import { ForgotPasswordPage } from "./Pages/ForgotPasswordPage/ForgotPasswordPage";
import { ErrorPage } from "./Pages/ErrorPage/ErrorPage";

function App(props: RouteComponentProps) {
  const [returnUrl, setReturnUrl] = useState("");
  const [logoutId, setLogoutId] = useState("");
  const [firstLoadStateSet, setFirstLoadStateSet] = useState(false);
  useEffect(() => {
    if (!firstLoadStateSet) {
      const params = new URLSearchParams(props.location.search);
      setReturnUrl(params.get("ReturnUrl") ?? "");
      setLogoutId(params.get("logoutId") ?? "");
      setFirstLoadStateSet(true);
    }
  }, [props.location.search, firstLoadStateSet]);

  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      if (event.key === "F5") {
        event.preventDefault();
      }
    });
    /*
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
    });
    */
  }, [window]);

  return (
    <div className="App">
      <AppContext.Provider value={{ returnUrl, logoutId }}>
        <ThemeProvider theme={BlueLeapTheme}>
          <Container maxWidth="xl">
            <BlueLeapHeader />
            <Container maxWidth="lg" style={{ height: "75%" }}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return <Redirect to="/error" />;
                    }}
                  />
                  <Route exact path="/login" component={LoginPage} />
                  <Route exact path="/register" component={RegisterPage} />
                  <Route
                    exact
                    path="/forgotpassword"
                    component={ForgotPasswordPage}
                  />
                  <Route exact path="/logout" component={LogoutPage} />
                  <Route exact path="/error" component={ErrorPage} />
                </Switch>
              </Box>
            </Container>
          </Container>
        </ThemeProvider>
      </AppContext.Provider>
    </div>
  );
}

interface AppContextProps {
  returnUrl?: string;
  enteredUsername?: string;
  firstLoginAfterRegistration?: boolean;
  logoutId?: string;
}

export const AppContext = React.createContext<AppContextProps>({
  returnUrl: "",
  logoutId: "",
});

export default App;

export const AppWithRouter = withRouter(App);
