import { Box, Card, Grid, Typography } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { ReactComponent as BlueLeapLogoBlue } from "../../Themes/BlueLeap/Assets/BlueLeap-Logo-Blue.svg";
/*
import "./css/amblue.webflow.css";
import "./css/webflow.css";
import "./css/normalize.css";
*/

const BlueLeapCardBase: React.FC<PropsWithChildren<{}>> = (props) => {
  return <Card variant="outlined" {...props} />;
};

export const BlueLeapCard = styled(BlueLeapCardBase)`
  width: 100%;
  height: 100%;
  border-radius: 18px;
  border: 1px solid;
  border-color: #008cff;
  .MuiCardContent-root:last-child {
    padding-bottom : 16px !important;
 }
`;

const BlueLeapCardHeaderTypography: React.FC<PropsWithChildren<{}>> = (
  props
) => {
  return <Typography {...props} />;
};

export const BlueLeapHeader: React.FC<PropsWithChildren<{}>> = (props) => {
  return (
    <Box pt="50px" pr="10px" pb="10px" pl="5px">
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} md={6} lg={3}>
          <BlueLeapLogoBlue width="150" />
        </Grid>
      </Grid>
    </Box>
  );
};

/*
export const BlueLeapFooter: React.FC<PropsWithChildren<{}>> = (
  props
) => {
  return (
    <footer id="footer" className="footer">
      <div className="w-container">
        <div className="footer-flex-container">
          <a href="#" className="footer-logo-link w-inline-block">
            <img
              src="images/BlueLeap-Logo-Blue.svg"
              alt=""
              className="footer-image"
            />
          </a>
          <div>
            <div className="text-block">
              BlueLeap, Inc.
              <br />
              3600 Cherokee Street
              <br />
              Suite #120 PMB 17294&nbsp;
              <br />
              Kennesaw, GA 30144 USA
            </div>
            <a
              href="mailto:knowmore@blueleap.com"
              className="link-block-5 w-inline-block"
            >
              <h4 className="heading-6 m-t-20 terms">knowmore@blueleap.com</h4>
            </a>
          </div>
          <div>
            <div className="text-block cc">
              Copyright © 2020 BlueLeap. All rights reserved.
            </div>
            <a href="tos.html" className="link-block-5 w-inline-block">
              <h4 className="heading-6 m-t-20 terms">Terms of Service</h4>
            </a>
          </div>
        </div>
        <div className="div-block-22"></div>
      </div>
    </footer>
  );
};
*/
