import { createMuiTheme } from "@material-ui/core/styles";

export const BlueLeapTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#008cff",
    },
    secondary: {
      main: "#24527e",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: `"Averta", "Roboto", "Helvetica", "Arial", sans-serif`,
    h3: {
      fontSize: "1.7rem",
    },
  },
});
