import Swal from "sweetalert2";

export const SuccessToast = Swal.mixin({
  icon: "success",
  title: "Success",
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export const ErrorToast = Swal.mixin({
  icon: "error",
  title: "Error",
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export const ConfirmationCodeAlert = Swal.mixin({
  icon: "question",
  title: "Enter Confirmation Code",
  toast: false,
  position: "center",
  showConfirmButton: true,
  confirmButtonText: "Submit Code",
  confirmButtonAriaLabel: "Submit Code",
  input: "text",
  allowOutsideClick: false,
  showLoaderOnConfirm: true,
  preConfirm: (code) => {
    if (!new RegExp("^\\d{6}$").test(code)) {
      Swal.showValidationMessage("Invalid code");
    }
  },
});

export const InformationAlert = Swal.mixin({
  icon: "info",
  title: "Important Information",
  toast: false,
  position: "center",
  showConfirmButton: true,
  confirmButtonText: "Got It",
  confirmButtonAriaLabel: "Got It",
  allowOutsideClick: false,
});
