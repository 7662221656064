import { Container } from "@material-ui/core";
import React, { useContext } from "react";
import { useEffect } from "react";
import { AppContext } from "../../App";

export const LogoutPage: React.FC<{}> = () => {
  let { logoutId } = useContext(AppContext);


  useEffect(() => {
    let logoutUser = async () => {
      if (logoutId) {
        const logoutRequest = await fetch(
          `${window.location.origin}/account/logout`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ logoutId }),
            credentials: "same-origin",
          }
        );
        if (logoutRequest.ok) {
          console.log("Using logoutId " + logoutId);
          let logoutResponse = await logoutRequest.json();
          console.log("Tried redirecting to: " + logoutResponse.redirectUrl);
          window.location.href = logoutResponse.redirectUrl;
        } else {
          console.log("request failed");
        }
      }
      else
      {
        console.log("Invalid logoutId");
      }
    };
    logoutUser();
  }, [logoutId]);
  return <Container>You will be redirected soon..</Container>;
};
