export const ValidationSettings = {
  nameMinimumCharacters: 2,
  passwordMinimumCharacters: 8,
};

export const ValidationMessages = {
  nameRequiredError: "A name is required to register",
  nameMinimumError: `Your name must have a minimum length of ${ValidationSettings.nameMinimumCharacters} characters`,
  emailFormatError:
    "Your email must be in a valid format, such as user@example.com",
  emailRequiredError: "An email is required to register",
  phoneNumberRequiredError: "Your phone number is required to register",
  passwordMinimumError: `A password requires minimum ${ValidationSettings.passwordMinimumCharacters} characters`,
  passwordRequiredError: "A password is required to register",
  confirmPasswordRequiredError: "Please re-enter your password",
  passwordsMustMatchError: "Entered passwords must match",
};
