import {
  Container,
  Typography,
  CardContent, IconButton, Box
} from "@material-ui/core";
import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { RouteComponentProps } from "react-router-dom";
import {
  ConfirmationCodeAlert,
  ErrorToast,
  SuccessToast,
} from "../../Components/Common/Alerts";
import { BlueLeapCard } from "../../Components/Common/BlueLeap";
import {
  ForgotPasswordForm,
  ForgotPasswordFormValues,
} from "../../Components/Forms/ForgotPasswordForm/ForgotPasswordForm";

export const ForgotPasswordPage: React.FC<RouteComponentProps> = (props) => {
  const handleForgotPasswordSubmit = async (
    values: ForgotPasswordFormValues
  ) => {
    let username = values.username;
    let newPassword = values.newPassword;
    const forgotPasswordRequest = await fetch(
      `${window.location.origin}/account/forgotpassword`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "omit",
        body: JSON.stringify({
          username: username,
        }),
      }
    );
    if (forgotPasswordRequest.ok) {
      await new Promise((r) => setTimeout(r, 2000));
      let confirmationCode = (
        await ConfirmationCodeAlert.fire({
          text: "Check your email",
        })
      ).value;

      const forgotPasswordConfirmRequest = await fetch(
        `${window.location.origin}/account/forgotpassword/confirm`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "omit",
          body: JSON.stringify({
            username: username,
            confirmationCode: confirmationCode,
            newPassword: newPassword,
          }),
        }
      );
      if (forgotPasswordConfirmRequest.ok) {
        SuccessToast.fire({
          text: "Password reset successfully",
        });
        props.history.push("/login");
      } else {
        ErrorToast.fire({ text: "Failed to reset password" });
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <BlueLeapCard>
        <CardContent>
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb={1}
          >
            <Box>
              <IconButton onClick={() => props.history.replace("/login")}>
                <ArrowBackIosIcon color="secondary" />
              </IconButton>
            </Box>
            <Box alignSelf="center">
              <Typography
                variant="h3"
                component="h2"
                children={"Forgot Password"}
                color="primary"
              />
            </Box>
            <Box width="3rem"></Box>
          </Box>
          <ForgotPasswordForm
            submitButtonText="Submit"
            onSubmit={handleForgotPasswordSubmit}
          />
        </CardContent>
      </BlueLeapCard>
    </Container>
  );
};
