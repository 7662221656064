import Box, { BoxProps } from "@material-ui/core/Box";
import React, { PropsWithChildren } from "react";

export const FormFieldContainer: React.FunctionComponent<PropsWithChildren<
  BoxProps
>> = (props) => {
  return (
    <Box flexDirection="row" justifyContent="center">
      {props.children}
    </Box>
  );
};
