import {
  Container,
  Typography,
  CardContent,
  IconButton,
  Box,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  RegisterForm,
  RegisterFormValues,
} from "../../Components/Forms/RegisterForm/RegisterForm";
import {
  ConfirmationCodeAlert,
  ErrorToast,
  SuccessToast,
} from "../../Components/Common/Alerts";
import { BlueLeapCard } from "../../Components/Common/BlueLeap";
import { AccountsClient } from "../../AccountsClient/AccountsClient";

export const RegisterPage: React.FC<RouteComponentProps> = (props) => {
  const handleRegisterSubmit = async (values: RegisterFormValues) => {
    let { name, username, password, phoneNumber } = values;

    const registerSuccess = await AccountsClient.Register(
      name,
      username,
      password,
      phoneNumber
    );
    if (registerSuccess) {
      SuccessToast.fire({
        text: "Registered successfully",
      });
      await new Promise((r) => setTimeout(r, 2000));
      let emailConfirmed = false;
      while (!emailConfirmed) {
        let confirmationCode = (
          await ConfirmationCodeAlert.fire({
            text: "Check your email",
          })
        ).value as string;
        let registerConfirmEmailResponse = await AccountsClient.RegisterConfirmEmail(
          confirmationCode,
          null
        );
        if (registerConfirmEmailResponse != null) {
          SuccessToast.fire({
            text: "Confirmed email successfully",
          });
          emailConfirmed = true;
          props.history.replace("/login");
          break;
        }
        else
        {
          ErrorToast.fire({
            text: "Failed to confirm your email. Please retry.",
          });
          await new Promise((r) => setTimeout(r, 2000));
        }

      }
    } else {
      ErrorToast.fire({
        text: "Registration failed ",
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <BlueLeapCard>
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb={1}
          >
            <Box>
              <IconButton onClick={() => props.history.replace("/login")}>
                <ArrowBackIosIcon color="secondary" />
              </IconButton>
            </Box>
            <Box alignSelf="center">
              <Typography
                variant="h3"
                component="h2"
                children={"Register"}
                color="primary"
              />
            </Box>
            <Box width="3rem"></Box>
          </Box>

          <RegisterForm
            submitButtonText="Register"
            onSubmit={handleRegisterSubmit}
          />
        </CardContent>
      </BlueLeapCard>
    </Container>
  );
};
