import { Button, InputAdornment } from "@material-ui/core";
import { AccountCircle, LockRounded, Phone } from "@material-ui/icons";
import { Field, FieldAttributes } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import styled from "styled-components";

const StyledTextFieldBase = ({ ...props }: FieldAttributes<any>) => {
  return (
    <Field
      component={TextField}
      fullWidth={true}
      margin="normal"
      color="secondary"
      variant="outlined"
      placeholder={props.label}
      InputLabelProps={{
        color: "secondary",
      }}
      {...props}
    />
  );
};

const ActionButtonBase = ({ ...props }: FieldAttributes<any>) => {
  return (
    <Button
      fullWidth={true}
      size="large"
      variant="contained"
      color="primary"
      {...props}
    />
  );
};

const SubmitButtonBase = ({ ...props }: FieldAttributes<any>) => {
  return (
    <ActionButton
      type="submit"
      fullWidth={true}
      size="large"
      variant="contained"
      color="primary"
      {...props}
    />
  );
};

const EmailFieldBase = ({ ...props }: FieldAttributes<any>) => {
  return (
    <StyledTextField
      type="email"
      autoComplete="username email"
      InputProps={{
        notched: false,
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle color="primary" />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

const PasswordFieldBase = ({ ...props }: FieldAttributes<any>) => {
  return (
    <StyledTextField
      type="password"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockRounded color="primary" />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

const VerificationCodeFieldBase = ({ ...props }: FieldAttributes<any>) => {
  return (
    <StyledTextField
      type="text"
      autoComplete="one-time-code"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockRounded color="primary" />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

const PhoneFieldBase = ({ ...props }: FieldAttributes<any>) => {
  return (
    <StyledTextField
      type="tel"
      autoComplete="tel"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Phone color="primary" />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export const StyledTextField = styled(StyledTextFieldBase)`
  fieldset {
    border-radius: 30px;
    border-color: #a9d7ff;
  }
`;

export const EmailField = styled(EmailFieldBase)``;

export const PasswordField = styled(PasswordFieldBase)``;

export const VerificationCodeField = styled(VerificationCodeFieldBase)``;

export const PhoneField = styled(PhoneFieldBase)``;

export const ActionButton = styled(ActionButtonBase)`
  padding: 9px 22px;
  border-radius: 30px;
`;

export const SubmitButton = styled(SubmitButtonBase)``;
